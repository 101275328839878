import moment from "moment";
import axios, { formToJSON } from "axios";
import { add } from "lodash";


export interface AddonProduct {
  visible: boolean;
  checked: boolean;
  sku: string;
  name: string;
  header: string;
  details: string;
  isAddon: boolean;
  isDeduction: boolean;
  lookupCode: string;
  recurring: string;
  count: number;
}

export interface AddonFacility {
  visible: boolean;
  checked: boolean;
  sku: string;
  name: string;
  header: string;
  details: string;
  lookupCode: string;
  recurring: string;
  count: number;
}

export interface Day {
  day: number;
  overnightGuests: number;
  totalGuests: number;
  occupancy: string[];
  seating: string[];
  addonProducts: AddonProduct[];
  addonFacilities: AddonFacility[];
}

export interface AddonProduct {
  visible: boolean;
  checked: boolean;
  sku: string;
  name: string;
  header: string;
  details: string;
  isAddon: boolean;
  isDeduction: boolean;
  lookupCode: string;
  recurring: string;
  count: number;
}

export interface IFormState {
  start: Date;
  end: Date;
  duration: string;
  meetingRooms: number;
  prevdayGuests: number;
  rooms: number;
  price: string;
  days: Day[];
  service: any;
  placeName: string;
  placeLat: number;
  placeLng: number;
}

const initialFormState = {
  start: moment().add(1, 'days').endOf('day').toDate(),
  end: moment().add(2, 'days').endOf('day').toDate(),
  duration: 'Ganztag',
  meetingRooms: 1,
  days: [
    {
      day: 0,
      overnightGuests: 0,
      totalGuests: 0,
      occupancy: ['FULLDAY'],
      seating: ['UFORM'],
      addonProducts: [],
      addonFacilities: []
    },
    {
      day: 1,
      overnightGuests: 0,
      totalGuests: 0,
      occupancy: ['FULLDAY'],
      seating: ['UFORM'],
      addonProducts: [],
      addonFacilities: []
    },
  ],
  rooms: 0,
  prevdayGuests: 0,
  placeName: typeof window !== 'undefined' ? new URLSearchParams(window?.location.search).get('place') || '' : '',
  placeLat: 0,//50.110924,
  placeLng: 0,//8.682127,
  price: '0',
  service: {
    sku: ''
  }
};

export const getUserLocation = async (): Promise<{ latitude: number, longitude: number, placeName: string }> => {
  return new Promise((resolve, reject) => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=AIzaSyAqGm1p5W-J2ghfJ4eSJq4yjpMS3yett7Y`)
          .then((res) => {
            const place = res.data.results[0].formatted_address;
            resolve({ latitude, longitude, placeName: place });
          })
          .catch((e) => {
            reject(e);
          })
      }, e => {
        reject(e);
      })
    } else {
      reject("Geolocation is not supported by this browser.");
    }
  })
}

const getPreviousState = () => {
  try {
    const urlParams = new URLSearchParams(window?.location.search);
    if (urlParams.get('s')) {
      const state = JSON.parse(urlParams.get('s') as any);
      state.start = new Date(state.start);
      state.end = new Date(state.end);
      return state;
    } else {
      console.log('No state in URL');
      throw new Error('No state in URL')
    }
  } catch (e) {
    try {
      if (localStorage.getItem('formState')) {
        const s = JSON.parse(localStorage.getItem('formState') as any);
        if (s !== null && s.start && s.end) {
          console.log('State in local storage');
          s.start = new Date(s.start);
          s.end = new Date(s.end);
          return s
        } else {
          console.log('No state in local storage');
          throw new Error('No state in local storage')
        }
      }
    } catch (e) {
      return null
    }
  }
  return null
}

export const getInitialFormState = async (): Promise<IFormState> => {
  /*try {
    const userLocation = await getUserLocation();
    console.log('Initital - User location: ' + userLocation.placeName);
    return {
      ...initialFormState,
      placeLat: userLocation.latitude,
      placeLng: userLocation.longitude,
      placeName: userLocation.placeName
    }
  } catch (e) {
    return initialFormState;
  }*/
    getUserLocation().then((res) => {
      updateFormState({
        ...initialFormState,
        placeLat: res.latitude,
        placeLng: res.longitude,
        placeName: res.placeName
      })
    }).catch((e) => {
      console.error(e);
    })
    return initialFormState
}

export const getFormState = async (): Promise<IFormState> => {
  const prevState = getPreviousState();
  if (prevState) {
    console.log('Previous state: ' + JSON.stringify(prevState));
    return prevState
  }
  try {
    const userLocation = await getUserLocation();
    console.log('User location: ' + userLocation.placeName);
    return {
      ...initialFormState,
      placeLat: userLocation.latitude,
      placeLng: userLocation.longitude,
      placeName: userLocation.placeName
    }
  } catch (e) {
    return initialFormState;
  }
}

export const isInitalFormState = async (state: IFormState) => {
  const initialFormState = await getInitialFormState()
  console.log('Initial form state: ' + JSON.stringify(initialFormState));
  console.log('Current form state: ' + JSON.stringify(state));
  return JSON.stringify(state) === JSON.stringify(initialFormState);
}

export const updateFormState = (state: IFormState) => {
  state.start = moment(state.start).endOf('day').toDate();
  state.end = moment(state.end).endOf('day').toDate();
  localStorage.setItem('formState', JSON.stringify(state));
  const event = new CustomEvent('formStateChange', { detail: state });
  window.dispatchEvent(event);
}
