import React from 'react';
import Divider from '../../Divider';
import NumField from '../../NumField';
import { CapsuleTabContentBody, CapsuleTabContentHeader } from '../CapsuleComponents';
import { addDays, format } from 'date-fns/fp';
import { IFormState } from '../../../semshared/utils/lister';

interface RoomSectionProps {
  formState: IFormState
  setFormState: (formState: IFormState) => void;
}

const RoomsSection = ({ formState, setFormState }: RoomSectionProps) => (
  <>
    <CapsuleTabContentHeader>Bitte geben Sie die Anzahl der Zimmer pro Tag an.</CapsuleTabContentHeader>
    <CapsuleTabContentBody>
      {!formState.start && <div></div>}
      <div
        style={{
          display: 'flex',
          fontFamily: 'Roboto, sans-serif',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            minWidth: 50,
            paddingRight: 40,
            textAlign: 'left',
          }}
        >
          <>
            <strong>Voranreisetag</strong>
            <div>{format('dd.MM.yyyy', addDays(-1, formState.start || new Date()))}</div>
          </>
        </div>
        <div
          style={{
            paddingRight: 0,
            alignSelf: 'center',
          }}
        >
          <NumField
            value={formState.prevdayGuests}
            onChange={value =>
              setFormState({
                ...formState,
                prevdayGuests: value,
              })
            }
          />
        </div>
      </div>
      <Divider />
      {formState.start &&
        [...Array(formState.days.length)].map((_, index) => (
          <React.Fragment key={index}>
            <div
              style={{
                display: 'flex',
                fontFamily: 'Roboto, sans-serif',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  minWidth: 50,
                  paddingRight: 40,
                  textAlign: 'left',
                }}
              >
                <>
                  <strong>{formState.days.length - 1 === index ? 'Verlängerungsnacht' : `Tag ${index + 1}`}</strong>
                  <div>{format('dd.MM.yyyy', addDays(index, formState.start || new Date()))}</div>
                </>
              </div>
              <div
                style={{
                  paddingRight: 0,
                  alignSelf: 'center',
                }}
              >
                <NumField
                  value={formState.days[index].overnightGuests}
                  onChange={value => {
                    setFormState({
                      ...formState,
                      days: formState.days.map((day, i) => {
                        if (index === i) {
                          return {
                            ...day,
                            overnightGuests: value,
                          };
                        } else {
                          return day;
                        }
                      }),
                    });
                  }}
                />
              </div>
            </div>
            <Divider />
          </React.Fragment>
        ))}
    </CapsuleTabContentBody>
  </>
);

export default RoomsSection;
